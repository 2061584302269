const TermsOfUse = () => {
  return (
    <div dir="rtl" className="terms-container">
      <h1 className="terms-title">תנאי שימוש</h1>
      <div className="terms-content">
        <h2>1. האתר</h2>
        <p>
          1.1. אתר האינטרנט הידוע בשם www.hzrit.co.il, דף הפייסבוק הידוע בשם
          "החזרית", "גוגל פלוס", דף ה"יו טיוב", דף הלינקדין הידועים בשם "hzrt"
          ו/או "hzrit" והאפליקציה הסלולרית הידועה בשם "החזרית" (כולם ביחד להלן:
          "האתר") פותחו, מופעלים, מנוהלים והינם בבעלות חברת החזרית ניהול החזרי
          הוצאות בקליק בע"מ, ח.פ. 515323061 (להלן: "החברה").
        </p>
        <p>
          1.2. האתר הינו פלטפורמה אינטרנטית וסלולרית שבאמצעותה יכול המשתמש לדווח
          על ההוצאות האישיות שלו ולבקש החזרים עבורן, להפיק דוחות ולהוציא העתקי
          חשבוניות שהגיש להחזר הוצאות. האפליקציה משולבת מצלמה לצורך דיווח החזרי
          ההוצאות של עובדי החברות הרשומות אליה. כל חשבונית מצולמת על ידי העובד
          באמצעות המכשיר הסלולרי שלו, ומועברת לגורם המטפל בלחיצת כפתור.
        </p>
        <p>
          1.3. האתר מאובטח ועובד בפתרון "ענן" עם יכולת לייצא נתונים למערכות
          הנה"ח קיימות ולהפיק דוחות שונים, ולפיכך השימוש באתר יתאפשר אך ורק
          באמצעות כניסה לחשבון שאותו פתחה החברה המשתמשת.
        </p>

        <h2>2. תנאים מוקדמים לשימוש באתר</h2>
        <p>
          2.1. תנאי שימוש אלו יחולו על כל פעולה ו/או שימוש שיבוצעו על ידי
          המשתמש.
        </p>
        <p>
          2.2. כל שימוש באתר וכן בכל אתר אחר שיחליף אותו ו/או יתווסף אליו, לרבות
          אך לא רק גלישה באתר, ביצוע ייצוא נתונים, ייבוא נתונים ו/או כל פעולה
          אחרת באתר, תחשב כהסכמת המשתמש לכל תנאי השימוש שלהלן והתחייבותו לנהוג
          לפיהם. המשתמש מצהיר ומתחייב כי לא תהיה לו כל טענה ו/או דרישה ו/או
          תביעה כנגד החברה ו/או מנהליה ו/או בעלי מניותיה ו/או מי מטעמה, למעט
          ביחס להפרת התחייבויות החברה לפי תנאי השימוש שלהלן.
        </p>
        <p>
          2.3. אם המשתמש אינו מסכים לתנאי אחד או יותר מתנאי השימוש האמורים להלן,
          הוא נדרש להימנע משימוש באתר ו/או מביצוע כל פעולה באתר.
        </p>
        <p>
          2.4. באתר רשאי להשתמש אך ורק משתמש שעונה על כל התנאים שלהלן במצטבר:
          הינו מעל גיל 18 או שהינו מתחת לגיל 18, אך כל פעולה שיעשה באתר, לרבות
          הגשת החזרי הוצאות, ייבוא נתונים, ייצוא נתונים, רכישת מוצרים, אושרה על
          ידי מעסיקו, נציגו כמשמעות מונח זה בחוק הכשרות המשפטית והאפוטרופסות,
          התשכ"ג- 1962 (להלן: "חוק הכשרות המשפטית") ובכפוף לקיום כל התנאים
          הקבועים בחוק הכשרות המשפטית לצורך קיום הפעולות שיעשה באתר.
        </p>

        <h2>3. כללי התנהגות באתר</h2>
        <p>
          3.1. המשתמש מצהיר ומתחייב כי כל שימוש שיעשה באתר ו/או באמצעות האתר,
          במישרין ו/או בעקיפין, יהיה אך ורק למטרות חוקיות ובהתאם לתנאי שימוש
          אלו.
        </p>
        <p>
          3.2. המשתמש מתחייב לעשות שימוש באתר אך ורק באמצעות כניסה לחשבון האמתי
          שלו שהקצה לו מעסיקו.
        </p>
        <p>
          3.3. המשתמש מתחייב לדווח על חשבוניות אמתיות בלבד, שהונפקו לו רק בעבור
          צורכי עבודה באותו עסק שבו הוא מועסק, בימים ובשעות העבודה שהוגדרו לו
          מראש.
        </p>
        <p>
          3.4. המשתמש מתחייב לא לכלול בפעילותו באתר כל מלל פוגעני ו/או מעליב
          ו/או המהווה לשון הרע ו/או בעל אופי פלילי ו/או המהווה עוולה כלשהי ו/או
          שיש בו הפרת הוראות כל דין כלפי מנהלו ו/או משתמש אחר ו/או כלפי החברה
          ו/או מעסיקו ו/או כלפי ספק כלשהו ו/או כלפי כל גורם אחר שהוא ו/או שיהיה
          בו משום הפרה של זכויות של צד ג'.
        </p>
        <p>
          3.5. על אף האמור בכל מקום אחר, החברה תהיה רשאית למנוע ממשתמש לעשות
          שימוש באתר ו/או לבצע דיווח חשבוניות ו/או להפיק דוחות באתר ו/או כל
          פעולה שהיא באתר, בין אם על ידי חסימתו ובין אם על ידי כל פעולה אחרת,
          בכל אחד מהמקרים הבאים:
        </p>
        <ul>
          <li>
            3.5.1. המשתמש ביצע, מבצע, או שיש לחברה יסוד סביר להניח כי הוא עתיד
            לבצע פעולה בניגוד לדין ו/או בניגוד להוראות תנאי שימוש אלו.
          </li>
          <li>
            3.5.2. המשתמש מסר באתר פרטים שאינם אמתיים ודיווח על חשבוניות שלא
            הונפקו לו כחלק מעבודתו באותו העסק.
          </li>
          <li>
            3.5.3. המשתמש דיווח על חשבוניות שאינן לצורכי עבודה ו/או חשבוניות
            ששימשו אותו באופן פרטי.
          </li>
          <li>
            3.5.4. המשתמש עשה או עתיד לעשות שימוש באתר שיש בו כדי לפגוע באופן
            כלשהו בחברה ו/או מעסיקו ו/או בספקים הפועלים באתר ו/או במשתמשים אחרים
            ו/או בצד שלישי כלשהו.
          </li>
          <li>
            3.5.5. כרטיס האשראי ו/או אמצעי תשלום אלקטרוני אחר שבו עשה המשתמש
            שימוש לצורך ביצוע רכישה באתר, נחסם לשימוש על ידי חברת כרטיסי האשראי
            ו/או הגורם הרלבנטי לאותו אמצעי תשלום.
          </li>
        </ul>

        <h2>4. סוגי פעולות באתר</h2>
        <p>4.1. משתמש יוכל לבצע באתר את הפעולות הבאות:</p>
        <ul>
          <li>4.1.1. לדווח על חשבוניות שבגינן הוא מבקש החזר הוצאות ממעסיקו.</li>
          <li>4.1.2. להפיק דוחות והעתקי חשבוניות במגוון חתכים.</li>
          <li>4.1.3. להתחבר למערכת ולרשום משתמשים נוספים.</li>
          <li>4.1.4. לדחות חשבוניות שלא עומדות בתנאים שהוגדרו מראש.</li>
          <li>4.1.5. לקבוע באילו זמנים מותר למשתמש לדווח על חשבוניות.</li>
          <li>
            4.1.6. לקבוע מגבלות לכל משתמש לפי סוג החזר וסכום החזר – לחשבונית
            ולחודש.
          </li>
        </ul>

        <h2>5. אופן התשלום באתר</h2>
        <p>
          5.1. בעת רכישת מנוי לאתר תופנה החברה המשתמשת לביצוע התשלום דרך שער
          התשלומים טרנזילה ו/או ה.ק אשראי ו/או הוראת קבע בנקאית ו/או כל שער
          תשלומים אחר לפי שיקול דעתה הבלעדי והמוחלט של החברה (להלן: "שער
          התשלומים").
        </p>
        <p>
          5.2. המשתמש מתחייב לבצע את התשלום בהתאם להוראות שיופיעו בשער התשלומים.
        </p>
        <p>
          5.3. תשלום על ידי משתמש ייחשב אך ורק תשלום שבוצע על ידי המשתמש בשער
          התשלומים ושבגינו הופק למשתמש על ידי שער התשלומים אישור תשלום.
        </p>

        <h2>6. ביטול עסקה</h2>
        <p>
          6.1. ביטול עסקה ייעשה באמצעות פניה בכתב 60 יום מראש למוקד שירות
          הלקוחות של החברה, בהתאם לפרטי ההתקשרות המופעים באתר HZRiT.co.il לרבות
          באמצעות פקסימיליה או באמצעות תקשורת אלקטרונית.
        </p>
        <p>
          6.2. במועד שבו המשתמש מאשר כי הוא מסכים לתנאי השימוש באתר, יתבקש
          המשתמש לבחור אם הוא מעוניין להצטרף לרשימת התפוצה של החברה לצורך פנייה
          אליו בדברי פרסומת, כמשמעות מונח זה בחוק התקשורת (בזק ושידורים),
          התשמ"ב- 1982 (להלן: "חוק התקשורת"). הסכמה של המשתמש לאמור וסימון
          אפשרות זו באתר, במקום המתאים לכך, יחשבו כאישור המשתמש לאמור לפי סעיף
          30א(ב) לחוק התקשורת. יובהר למען הסר כל ספק, כי בפני המשתמש שהסכים לקבל
          דברי פרסומת מהחברה כאמור, עומדת האפשרות להסרתו מרשימת התפוצה של החברה
          בכל עת, בהתאם להוראות חוק התקשורת.
        </p>
        <p>
          6.3. החברה מודיעה למשתמש כי יתכן שהחברה תשתמש ב"קבצי עוגייה" שיועברו
          לדפדפן של המשתמש, במחשב המשתמש. "קבצי העוגייה" משמשים באופן כללי, בין
          היתר (אך לא רק), להתאמת חוויית הגלישה באינטרנט לגולש הספציפי, לזיהוי
          משתמשים, לאבטחת פעילויות באתר, למתן הצעות שיווקיות, לצפייה בבאנרים
          ועוד. המשתמש יכול לנטרל את "קבצי העוגייה" במחשבו האישי.
        </p>

        <h2>7. אחריות</h2>
        <p>
          7.1. מבלי לגרוע מכלליות כל האמור לעיל, בכל מקרה, האחריות הכוללת של
          החברה ביחס לכל נזק ו/או הפסד ו/או הוצאה שיגרמו למשתמש ו/או לכל גורם
          אחר שהוא בקשר עם האתר ו/או השימוש בו ו/או פעולה כלשהי שנעשתה באתר,
          תהיה מוגבלת אך ורק לנזקים ישירים ומוחשיים אשר יגרמו למשתמש ו/או לכל
          גורם שהוא כתוצאה מהפרת התחייבויות החברה לפי דין ו/או לפי תנאי שימוש
          אלו, להבדיל מנזקים מיוחדים, תוצאתיים, עקיפים ו/או כל נזק אחר שהוא,
          ואחריותה הכוללת של החברה לנזקים כאמור לא תעלה בשום מקרה, יהיו העילות
          אשר יהיו וגובה הנזקים אשר יהיו, על הסכום ששולם לחברה על ידי הניזוק
          בגין המנוי ו/או העסקה הרלבנטיים לחודש שירות אחד.
        </p>
        <p>
          7.2. המשתמש מצהיר כי הינו מודע לכך שעלולות להיות הפרעות ו/או הפסקות
          טכניות כלשהן בפעולת האתר שאינן בשליטת החברה, לרבות (ומבלי לגרוע
          מכלליות האמור) אפשרות הגלישה לאתר ובאתר ו/או איבוד מידע באתר, לרבות
          איבוד נתונים ביחס למשתמש ו/או חוסר יכולת לבצע רכישת מנוי ו/או פעולות
          אחרות באתר. כמו כן, החברה תהיה רשאית להפסיק זמנית את פעולת האתר לשם
          ביצוע פעולות תחזוקה ו/או פעולות אחרות הנדרשות לצורך הפעלה סדירה ותקינה
          של האתר, ללא כל הודעה מראש. המשתמש לא יטענו כל טענה כלפי החברה ו/או לא
          ידרשו כל דרישה מהחברה בקשר לנ"ל.
        </p>
        <p>
          7.3. מבלי לגרוע מכלליות האמור, החברה אינה מתחייבת כי הנגישות לאתר,
          הצגת המידע אודות החשבוניות והדוחות וזמינותו לא יופרעו, יינתנו כסדרם
          ובלא הפסקות ו/או שלא ייתכנו בהם נזקים, קלקולים, תקלות ו/או כשלים והיא
          לא תהיה אחראית לכל נזק ו/או הוצאה ו/או הפסד, מכל סוג, במישרין ו/או
          בעקיפין, אשר ייגרמו למשתמש ו/או לכל גורם אחר בשל כך, בין אם נגרמו
          במזיד על ידי צד ג' כלשהו ובין אם נגרמו בשל תקלה כלשהי. המשתמש לא יטען
          כל טענה כלפי החברה ו/או ידרוש כל דרישה מהחברה בשל כך.
        </p>
        <p>
          7.4. ללקוחות המשתמשים בחבילת "החזרית דיגיטל" המשתמש אחראי לבדו על
          שלמות סריקת המסמכים למערכת החזרית וכי המסמך המקורי תואם את ההעתק
          הצילומי שהתקבל כתהליך הסריקה. המשתמש מצהיר כי בדק כל מסמך בנפרד ולא
          לתהליך בכללותו טרם חתם אלקטרונית. המשתמש לא יטען כל טענה כלפי החברה
          ו/או לא ידרוש כל דרישה מהחברה בקשר לשלמות סריקת המסמך המקורי, וכמו כן
          יבדוק שהמסמך המקורי תואם את ההעתק הצילומי שהתקבל על ידו או על ידי
          עובדי חברתו.
        </p>

        <h2>8. קניין רוחני</h2>
        <p>
          האתר, כל מידע המופיע בו, תוכנו, תמונות שבו והשלובות בו, מידע בכל צורה
          ואופן שהוא הכלול בו, לרבות מוזיקה השלובה בו, גרפיקת האתר, סימנים
          מסחריים המופיעים בו, הלוגיים (logos), לרבות השם והלוגו "החזרית", הינם
          קניינה הבלעדי והמוחלט של החברה וחל איסור מוחלט לבצע בהם ו/או בקשר
          אליהם העתקה, פרסום, הפצה, שינוי, מכירה, עבודות נגזרות, במישרין ו/או
          בעקיפין, ללא אישור מראש ובכתב מהחברה.
        </p>

        <h2>9. שירות לקוחות</h2>
        <p>
          בכל דבר ועניין בקשר לאתר ו/או לתנאי השימוש ו/או לכל פעולה שבוצעה באתר,
          ניתן לפנות למוקד שירות הלקוחות של החברה בטלפון 073-2248686 או בדואר
          אלקטרוני hd@hzrit.co.il
        </p>

        <h2>10. שונות</h2>
        <p>
          10.1. בכל מקום בתנאי שימוש אלו שנוקטים בלשון זכר הכוונה הינה גם לנקבה.
        </p>
        <p>
          10.2. כותרות הסעיפים מובאות לצרכי נוחות הקריאה בלבד ואין לעשות בהן כל
          שימוש פרשני שהוא.
        </p>
        <p>
          10.3. זכות השימוש באתר הינה זכות אישית למשתמש ולא ניתן להעבירה לכל
          גורם אחר.
        </p>
        <p>
          10.4. החברה תהיה רשאית לשנות ו/או להפסיק בכל עת לפי שיקול דעתה הבלעדי
          כל דבר ועניין ביחס לאתר ולפעילותו, לרבות תנאי השימוש, המוצרים, אופי
          פעילותו של האתר, עצם פעילותו של האתר ועוד.
        </p>
        <p>
          10.5. תנאי שימוש אלה ניתנים לשינוי בכל עת על ידי החברה לפי שיקול דעתה
          הבלעדי וזאת ללא כל תנאי מוקדם ומבלי שיהיה על החברה להודיע על כך מראש
          למשתמש ו/או לכל גורם שהוא, ובלבד שכל שינוי שייעשה יחול אך ביחס לשימוש
          שייעשה באתר ו/או לשירות שנרכש באתר לאחר השינוי.
        </p>
        <p>
          10.6. שום התנהגות של החברה לא תחשב כוויתור על זכויותיה לפי תנאי שימוש
          אלו ו/או כוויתור ו/או הסכמה להפרה או לאי קיום תנאי מתנאי שימוש אלו.
          ויתור או ארכה שניתנו על ידי החברה, במפורש או במשתמע, במקרה מסוים, לא
          יהוו ויתור או ארכה במקרה אחר, בין שבאותו עניין ובין בעניין אחר.
        </p>
        <p>
          10.7. רישומי המחשב וספרי החשבונות של החברה ייחשבו ראיה לכל פעולה ו/או
          רכישה שבוצעה באתר.
        </p>
        <p>
          10.8. לבית המשפט המוסמך במחוז תל אביב תהיה הסמכות הבלעדית לדון בכל דבר
          ועניין הנוגע לתנאי שימוש אלה, לכל פעולה שתבוצע באמצעות באתר ולכל סכסוך
          הנוגע לאתר.
        </p>

        <h2>11. הגנת פרטיות</h2>
        <p>
          ייתכן שהמשתמשים יידרשו למלא את פרטיהם האישים, כגון שמם או כתובת הדואר
          האלקטרוני שלהם במטרה ליהנות מחלק מהשירותים באתר או על מנת לרכוש מנוי
          לאתר בו ידרשו לתת פרטים נוספים. אייץ זט אר טי מתחייבת לעשות שימוש
          במידע זה רק על פי המותר בחוק. אייץ זט אר טי בע"מ מתחייבת שלא למסור את
          הפרטים האישיים של המשתמשים לצדדים שלישיים בניגוד לדין. עם זאת, במידה
          וחשיפת הפרטים תידרש ע"י גוף ממשלתי הפועל על פי דין או על פי צו בית
          משפט או במסגרת הליכים משפטיים המתנהלים כנגד אייץ זט אר טי בע"מ, תחשוף
          אייץ זט אר טי בע"מ את הפרטים האישיים שנמסרו ע"י המשתמש הרלוונטי תוך
          שמירה על פרטיותו של המשתמש ככל שניתן בנסיבות.
        </p>

        <h2>12. הגנת פרטיות</h2>
        <p>
          12.ייתכן שהמשתמשים יידרשו למלא את פרטיהם האישים, כגון שמם או כתובת
          הדואר האלקטרוני שלהם במטרה ליהנות מחלק מהשירותים באתר או על מנת לרכוש
          מנוי לאתר בו ידרשו לתת פרטים נוספים. אייץ זט אר טי מתחייבת לעשות שימוש
          במידע זה רק על פי המותר בחוק. אייץ זט אר טי בע"מ מתחייבת שלא למסור את
          הפרטים האישיים של המשתמשים לצדדים שלישיים בניגוד לדין. עם זאת, במידה
          וחשיפת הפרטים תידרש ע"י גוף ממשלתי הפועל על פי דין או על פי צו בית
          משפט או במסגרת הליכים משפטיים המתנהלים כנגד אייץ זט אר טי בע"מ, תחשוף
          אייץ זט אר טי בע"מ את הפרטים האישיים שנמסרו ע"י המשתמש הרלוונטי תוך
          שמירה על פרטיותו של המשתמש ככל שניתן בנסיבות.
        </p>
      </div>
    </div>
  );
};

export default TermsOfUse;
