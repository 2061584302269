import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./pages/Error";
import TermsOfUse from "./pages/Terms";

const router = createBrowserRouter([
  {
    path: "/terms",
    element: <TermsOfUse />,
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
